import { Link, useMatch } from '@remix-run/react';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import pluralize from 'pluralize';
import { Fragment } from 'react';

import {
  type DtoEvent,
  type DtoOrganizer,
  EnumsEventType,
} from '@lp-lib/api-service-client/public';

import { fromDTOGamePack } from '../../utils/api-dto';
import { CopyLinkButtonWithPreview } from '../common/CopyButton';
import { GamePackCover } from '../Game/Utilities';
import { useUser } from '../UserContext';

function EventLinkDetails(props: { event: DtoEvent }) {
  const { event } = props;
  return (
    <Link className={`text-primary`} to={`/events/${event.id}`}>
      Details
    </Link>
  );
}

function EventLinkEdit(props: { event: DtoEvent }) {
  const { event } = props;

  return (
    <Link className='text-primary' to={`/events/${event.id}/edit`}>
      Edit
    </Link>
  );
}

function EventLinkStart(props: { event: DtoEvent }) {
  const { event } = props;
  return (
    <Link className='text-primary' to={`/events/${event.id}/play`}>
      Start
    </Link>
  );
}

function EventLinks(props: { event: DtoEvent }) {
  const { event } = props;

  const match = useMatch('/events/:eventId');
  const user = useUser();

  const links = [];
  switch (event.type) {
    case 'live':
      break;
    case 'ond':
      if (!match) {
        links.push(<EventLinkDetails event={event} />);
      }
      if (event.organizerUid === user.id) {
        links.push(<EventLinkEdit event={event} />);
      }
      links.push(<EventLinkStart event={event} />);
      break;
    default:
      break;
  }

  if (links.length === 0) return null;
  return (
    <div className='text-sms flex gap-1'>
      {links.map((link, index) => (
        <Fragment key={index}>
          {link}
          {index < links.length - 1 && <span>|</span>}
        </Fragment>
      ))}
    </div>
  );
}

function AttendeesPreview(props: {
  attendees: DtoOrganizer[];
  previewCount: number;
}): JSX.Element | null {
  const { attendees, previewCount } = props;

  if (!attendees || attendees.length === 0) {
    return <>No attendees invited</>;
  }

  const items = [
    attendees
      .slice(0, previewCount)
      .map((o) => o.firstName || o.email)
      .join(', '),
  ];

  if (attendees.length - previewCount > 0) {
    const n = attendees.length - previewCount;
    items.push(`${n} ${pluralize('other', n)}`);
  }

  return <>Invitees: {items.join(' and ')}</>;
}

export function EventRow(props: { event: DtoEvent }): JSX.Element {
  const { event } = props;
  const startAt = utcToZonedTime(event.startAt, event.timezone);
  const endAt = utcToZonedTime(event.endAt, event.timezone);

  return (
    <section
      className='
        w-full
        bg-modal
        border border-secondary rounded-xl
        px-4 py-4
        flex gap-6
      '
    >
      <div className='flex-shrink-0 w-55'>
        <GamePackCover pack={fromDTOGamePack(event.gamePack)} />
      </div>
      <div className='flex-grow flex flex-col gap-1.5'>
        <header className='text-xl font-bold'>
          {event.type === EnumsEventType.EventTypeLive ? (
            <>The Luna Park Show (Live)</>
          ) : (
            event.gamePack.name
          )}
        </header>
        <time
          dateTime={startAt.toISOString()}
          className='text-sms flex flex-col'
        >
          <span>{format(startAt, 'EEE, MM/dd/yy')}</span>
          <span>{`${format(startAt, 'h:mm a')} - ${format(
            endAt,
            'h:mm a'
          )}`}</span>
          <span>{event.timezone}</span>
        </time>
        <div className='text-sms'>
          {event.type === EnumsEventType.EventTypeLive ? (
            <></>
          ) : (
            <AttendeesPreview
              attendees={event.attendees || []}
              previewCount={3}
            />
          )}
        </div>
        <EventLinks event={event} />
      </div>

      <aside className='flex-growshrinkmin flex flex-col gap-2'>
        <header className='text-xl font-bold'>Your Event Link</header>
        <CopyLinkButtonWithPreview
          urlToCopy={`${window.origin}/events/${props.event.id}/play`}
        />
        <div className='text-xs text-secondary'>
          Share this link with your attendees to grant them access to the event.
        </div>
      </aside>
    </section>
  );
}
